<template>
  <el-dialog
    title="模板消息详情"
    :visible.sync="show"
    center
    width="700px"
    top="100px"
    append-to-body
    :before-close="handleClose"
  >
    <div class="service-detail">
      <el-form label-width="120px" class="form-container">
        <!-- <el-form-item label="公众号：">
          <span>{{ form.channelName }}</span>
        </el-form-item> -->
        <el-form-item label="备注：">
          <span>{{ form.remark || "无" }}</span>
        </el-form-item>
        <el-form-item label="互动类型">
          <el-tag v-if="form.tagType === 0" type="info">全部</el-tag>
          <el-tag v-if="form.tagType === 1">活跃</el-tag>
          <el-tag v-if="form.tagType === 2">不活跃</el-tag>
        </el-form-item>
        <el-form-item label="接收用户">
          <el-tag v-if="form.sendAll === 1" type="info">全部用户</el-tag>
          <el-tag v-if="form.sendAll === 0">标签用户</el-tag>
        </el-form-item>
        <el-form-item label="送达人数限制">
          {{ form.fixedNum }}人
        </el-form-item>

        <el-form-item
          label="关注时间"
          v-if="form.sendAll === 0 && (form.startDate || form.endDate)"
        >
          {{ form.startDate }} - {{ form.endDate }}
        </el-form-item>
        <el-form-item label="充值情况" v-if="form.sendAll === 0">
          <span v-if="form.rechargeType === 0">不限</span>
          <span v-else-if="form.rechargeType === 1"> 未充值</span>
          <span v-else>已充值</span>
        </el-form-item>

        <el-form-item
          label="充值间隔"
          v-if="form.sendAll === 0 && form.rechargeType === 2"
        >
          <span
            v-if="!form.rechargeTimeSpaceStart && !form.rechargeTimeSpaceEnd"
            >无</span
          >
          <span v-else-if="!form.rechargeTimeSpaceEnd">
            {{ form.rechargeTimeSpaceStart }} 天以上
          </span>
          <span v-else-if="!form.rechargeTimeSpaceStart">
            {{ form.rechargeTimeSpaceEnd }} 天以下
          </span>
          <span v-else>
            {{ form.rechargeTimeSpaceStart }} - {{ form.rechargeTimeSpaceEnd }}
            <span style="margin-left: 20px">天</span>
          </span>
        </el-form-item>
        <el-form-item
          label="剩余书币"
          v-if="form.sendAll === 0 && (form.maxKanDian || form.minKanDian)"
        >
          <span
            style="margin-right: 50px"
            v-if="form.maxKanDian || form.minKanDian"
            >{{ form.minKanDian ? form.minKanDian : "" }} -
            {{ form.maxKanDian ? form.maxKanDian : "" }}</span
          >
          <span v-else style="margin-right: 50px">无</span>
        </el-form-item>
        <el-form-item
          label="累计充值"
          v-if="form.rechargeType === 2 && form.sendAll === 0"
        >
          <span v-if="!form.minTotalRecharge && !form.maxTotalRecharge">
            无
          </span>
          <template v-else>
            <span style="margin-right: 50px"
              >{{ form.minTotalRecharge }} - {{ form.maxTotalRecharge }}
              <span style="margin-left: 20px">元</span></span
            >
            <span
              style="margin-right: 50px"
              v-if="!form.minTotalRecharge && !form.maxTotalRecharge"
              >无</span
            >
          </template>
        </el-form-item>
        <el-form-item
          label="充值次数"
          v-if="
            form.rechargeType === 2 &&
            form.sendAll === 0 &&
            (form.minRechargeCount || form.maxRechargeCount)
          "
        >
          <span v-if="!form.minRechargeCount && !form.maxRechargeCount">-</span>
          <template v-else>
            <span
              style="margin-right: 50px"
              v-if="!form.minRechargeCount && !form.maxRechargeCount"
              >无</span
            >
            <span style="margin-right: 50px" v-else>
              {{ form.minRechargeCount }} - {{ form.maxRechargeCount }}
              <span style="margin-left: 20px">次</span></span
            >
          </template>
        </el-form-item>
        <el-form-item
          label="近7日充值"
          v-if="
            form.rechargeType === 2 &&
            form.sendAll === 0 &&
            (form.maxSevenRecharge || form.minSevenRecharge)
          "
        >
          <span v-if="!form.minSevenRecharge && !form.maxSevenRecharge">-</span>
          <template v-else>
            <span
              style="margin-right: 50px"
              v-if="!form.minSevenRecharge && !form.maxSevenRecharge"
              >无</span
            >
            <span style="margin-right: 50px" v-else>
              {{ form.minSevenRecharge }} - {{ form.maxSevenRecharge }}
              <span style="margin-left: 20px">元</span></span
            >
          </template>
        </el-form-item>
        <el-form-item
          label="是否vip"
          v-if="form.rechargeType === 2 && form.sendAll === 0"
        >
          <span v-if="form.isVip === 0">否</span>
          <span v-if="form.isVip === 1">是</span>
          <span v-if="form.isVip === 2">不限</span>
        </el-form-item>
        <el-form-item
          label="连续付费用户"
          v-if="form.rechargeType === 2 && form.sendAll === 0"
        >
          <span>{{ form.continuousRecharge ? "是" : "不限" }}</span>
        </el-form-item>
        <el-form-item
          label="RFM"
          v-if="form.rechargeType === 2 && form.sendAll === 0 && form.rfm"
        >
          <el-tag v-if="form.rfm.includes('0')" type="info">不限</el-tag>
          <el-tag
            v-if="form.rfm.includes('1')"
            class="margin-right-ten"
            type="success"
            >A类</el-tag
          >
          <el-tag
            v-if="form.rfm.includes('2')"
            class="margin-right-ten"
            type="warning"
            >B类</el-tag
          >
          <el-tag
            v-if="form.rfm.includes('3')"
            class="margin-right-ten"
            type="danger"
            >C类</el-tag
          >
        </el-form-item>
        <el-form-item
          label="优惠敏感度"
          v-if="form.rechargeType === 2 && form.sendAll === 0"
        >
          <el-tag v-if="form.couponLevel === 0" type="info">不限</el-tag>
          <el-tag v-if="form.couponLevel === 1">1级</el-tag>
          <el-tag v-if="form.couponLevel === 2">2级</el-tag>
          <el-tag v-if="form.couponLevel === 3">3级</el-tag>
          <el-tag v-if="form.couponLevel === 4">4级</el-tag>
          <el-tag v-if="form.couponLevel === 5">5级</el-tag>
        </el-form-item>
        <el-form-item label="性别" v-if="form.sendAll === 0">
          <span v-if="form.sex === 0">不限</span>
          <span v-else-if="form.sex === 1">男</span>
          <span v-else>女</span>
        </el-form-item>
        <el-form-item
          label="所在地区"
          v-if="form.sendAll === 0 && (form.city || form.province)"
        >
          <span v-if="!form.province && !form.city">-</span>
          <template v-else>
            <span style="margin-right: 50px" v-if="!form.province && !form.city"
              >无</span
            >
            <span style="margin-right: 50px" v-else>
              {{ form.province }} - {{ form.city }}
              <span style="margin-left: 20px"></span
            ></span>
          </template>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">关 闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { detailTemplateTag } from "@/api/templateMessage";
import { mapGetters } from "vuex";
export default {
  name: "edit",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    account: {
      type: Array,
      default: () => [],
    },
    info: Object,
  },
  data() {
    return {
      testId: null,
      estimateNum: null,
      form: {},
      textStr: "", // 文本内容
      textIndex: 0,
      insertBook: false,
      formLoading: false,
      showFlag: false,
    };
  },
  computed: {
    ...mapGetters(["officialList"]),
  },
  watch: {
    show: {
      handler(newVal) {
        if (newVal && this.info) {
          this.initData();
        }
      },
      immediate: true,
    },
  },
  methods: {
    initData() {
      this.formLoading = true;
      detailTemplateTag(this.info.id)
        .then((res) => {
          const { data, ...other } = res;
          this.form = other;
        })
        .finally(() => {
          this.formLoading = false;
        });
    },
    handleClose() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog__wrapper {
  overflow: hidden;
}
/deep/ .el-dialog__body {
  padding-bottom: 0;
}

.service-detail {
  box-sizing: border-box;
  padding: 20px;
  height: 600px;
  overflow: auto;
}
.dialog-footer {
  display: inline-block;
  width: 100%;
  // margin-top:20px;
  text-align: center;
}
.form-container {
  max-height: 600px;
  // overflow: auto;
  padding-right: 10px;
}

.example {
  width: 375px;
  background-color: #f1f1f1;
  padding: 10px;
}

.card {
  width: 100%;
  padding: 10px;
  background-color: #fff;
  border-radius: 4px;
}

.mesg-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  font-weight: bold;
  line-height: 20px;
}

.detail {
  display: flex;
  align-items: center;
}

.detail p {
  flex: 1;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 15px;
}

.review-box {
  width: 240px;
  margin: 0 4px;
  background: #f3f3f3;
  height: 100%;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  .review-text {
    margin: 20px 0;
    text-align: center;
    color: #bebebe;
    font-size: 16px;
  }
  .review {
    width: 220px;
    .review-item {
      background-color: #fff;
      padding: 6px;
      line-height: 16px !important;
      .title {
        font-size: 14px;
        margin-bottom: 10px;
      }
      .desc {
        font-size: 12px;
        margin-bottom: 10px;
        line-height: 14px;
      }
      .item {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 10px;
        .name {
          font-size: 14px;
          color: #b4b4b4;
          width: 90px;
        }
        .value {
          width: 120px;
          font-size: 12px;
          margin-left: 20px;
          line-height: 14px;
          p {
            line-height: 16px;
          }
        }
      }
    }
    .review-bottom {
      padding: 10px;
      background-color: #fff;
      border-top: 1px solid #eee;
      p {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}
</style>
