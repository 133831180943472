<template>
  <el-drawer
    :title="info ? '模板列表：' + info.remark : ''"
    :visible.sync="show"
    center
    size="80vw"
    append-to-body
    :before-close="handleClose"
  >
    <div class="tag-box">
      <dy-table
        :dataList="tableData"
        :rowList="rowList"
        :loading="loading"
        height="650px"
        @pageChange="handlePageChange"
      >
        <template #filter>
          <div class="search-box">
            <el-button class="margin-left-ten" @click="handlePageChange"
              >刷新数据</el-button
            >
            <div class="search-right">
              <el-button
                type="primary"
                @click="handleAddTemplate"
                icon="el-icon-plus"
                >模板</el-button
              >
            </div>
          </div>
        </template>
        <template #status="{ row }">
          <el-tag type="danger" size="mini" v-if="row.status == 2">删除</el-tag>
          <el-tag type="primary" size="mini" v-else-if="row.status == 1"
            >正常</el-tag
          >
        </template>
        <template #action="{ row }">
          <div>
            <el-button
              type="info"
              icon="el-icon-s-order"
              circle
              @click="handleDetail(row)"
            >
            </el-button>
            <el-button
              type="success"
              icon="el-icon-edit"
              circle
              @click="handleEdit(row)"
            >
            </el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              @click="handleDelete(row)"
            >
            </el-button>
          </div>
        </template>
      </dy-table>
    </div>
    <!-- 新增编辑 -->
    <eidtTemplate
      :show="showAddTemplate"
      :account="account"
      :accountId="accountId"
      :info="info"
      :detail="currentTemplate"
      @close="showAddTemplate = false"
      @refresh="handlePageChange"
    />
    <!-- 详情 -->
    <detailTemplate
      v-if="showDetail"
      :detail="currentTemplate"
      :show="showDetail"
      @close="showDetail = false"
    />
  </el-drawer>
</template>

<script>
import detailTemplate from "./detail.vue";
import {
  getTemplateContent,
  deleteTemplateContent,
} from "@/api/templateMessage";
import eidtTemplate from "./eidtTemplate.vue";
export default {
  name: "edit",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    info: Object,
    account: Array,
    accountId: Number,
  },
  components: {
    eidtTemplate,
    detailTemplate,
  },
  data() {
    return {
      CopyOfficial: [],
      copyNum: null,
      form: {},
      tableData: [],
      rowList: [
        {
          prop: "id",
          label: "ID",
        },
        {
          prop: "templateId",
          label: "模板id",
          colWidth: "200",
          popover: true,
        },
        {
          prop: "templateName",
          label: "模板名称",
        },
        {
          prop: "status",
          slot: true,
          label: "模板状态",
        },
        {
          prop: "urlName",
          label: "链接",
        },
        {
          prop: "failReason",
          label: "失败原因",
        },
        {
          prop: "action",
          label: "操作",
          slot: true,
        },
      ],
      loading: false,
      currentTemplate: null,
      showAddTemplate: false,
      showDetail: false,
    };
  },
  watch: {
    show: function (nv) {
      if (nv) {
        this.handlePageChange();
      }
    },
  },
  computed: {},
  methods: {
    handleDetail(row) {
      this.currentTemplate = row;
      this.showDetail = true;
    },
    handleEdit(row) {
      this.currentTemplate = row;
      this.showAddTemplate = true;
    },
    handleDelete(row) {
      this.$confirm("确定要删除此条模板?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteTemplateContent(row.id).then((res) => {
          this.$message.success("删除成功");
          this.handlePageChange();
        });
      });
    },
    handleAddTemplate() {
      this.currentTemplate = null;
      this.showAddTemplate = true;
    },
    handlePageChange() {
      if (!this.info) {
        return;
      }
      this.loading = true;
      getTemplateContent({
        tagId: this.info.id,
      })
        .then((res) => {
          this.tableData = res;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleClose() {
      this.$emit("close");
    },
  },
  created() {
    this.handlePageChange();
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog__wrapper {
  overflow: hidden;
}
/deep/ .el-dialog__body {
  padding-bottom: 0;
}

.service-detail {
  box-sizing: border-box;
  padding: 20px;
  height: 780px;
  overflow: auto;
}
.dialog-footer {
  display: inline-block;
  width: 100%;
  // margin-top:20px;
  text-align: center;
}
.form-container {
  max-height: 800px;
  // overflow: auto;
  padding-right: 10px;
}
.search-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .search-right {
    margin-right: 10px;
  }
}
</style>
