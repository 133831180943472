import request from './config'

// 获取方案
export function getTemplatePlan(params) {
  return request.get('/platform/templatePlan', { params })
}

// 获取无效模板
export function getInvalidTemplatePlan(params) {
  return request.get('/platform/templatePlan/invalid', { params })
}

// 新增方案
export function addTemplatePlan(params) {
  return request.post('/platform/templatePlan', params)
}

// 修改方案
export function changeTemplatePlan(id, params) {
  return request.put(`/platform/templatePlan/${id}`, params)
}

// 删除方案
export function deleteTemplatePlan(id) {
  return request.delete(`/platform/templatePlan/${id}`)
}

// 复制方案
export function copyTemplatePlan(params) {
  return request.post(`/platform/templatePlan/copy`, params)
}

// 暂停方案
export function stopTemplatePlan(id) {
  return request.put(`/platform/templatePlan/stop/${id}`)
}

// 标签相关

// 查询标签
export function getTemplateTag(params) {
  return request.get('/platform/templateTag', { params })
}

// 新增标签
export function addTemplateTag(params) {
  return request.post('/platform/templateTag', params)
}
// 删除标签
export function deleteTemplateTag(id) {
  return request.delete(`/platform/templateTag/${id}`)
}
// 修改标签
export function changeTemplateTag(id, params) {
  return request.put(`/platform/templateTag/${id}`, params)
}

// 复制标签
export function copyTemplateTag(id) {
  return request.put(`/platform/templatePlan/tag/copy/${id}`)
}

// 标签详情
export function detailTemplateTag(id) {
  return request.get(`/platform/templateTag/${id}`)
}

// 模板相关
// 查询模板
export function getTemplateContent(params) {
  return request.get('/platform/templateContent', { params })
}

// 模板消息获取公众号模板
export function getWechatTemplate(id) {
  return request.get(`/platform/templateContent/remote/${id}`)
}

// 新增模板
export function addTemplateContent(params) {
  return request.post('/platform/templateContent', params)
}
// 删除模板
export function deleteTemplateContent(id) {
  return request.delete(`/platform/templateContent/${id}`)
}
// 修改模板
export function changeTemplateContent(id, params) {
  return request.put(`/platform/templateContent/${id}`, params)
}

// 测试发送
export function sendTemplateContentTest(params) {
  return request.post(`/platform/templateContent/test`, params)
}
