<template>
  <div>
    <div
      class="
        part
        border-radius-6
        box-shadow-light-grey
        padding-20
        height-calc-type1
      "
    >
      <div class="operation padding-bottom-20">
        <div class="operation-right">
          <el-button class="margin-right-twentyFour" @click="handlePageChange">
            刷新数据
          </el-button>
          <el-select
            v-model="accountChannelId"
            placeholder="请选择公众号"
            clearable
            style="width: 140px; margin-right: 10px"
            filterable
          >
            <el-option
              v-for="item in channelList"
              :key="item.id"
              :label="item.channelName"
              :value="item.id"
            >
              <span style="float: left">{{ item.channelName }}</span>
              <span style="float: right; margin-left: 15px; font-size: 13px"
                >{{ item.platformStr }} - {{ item.uname }}</span
              >
            </el-option>
          </el-select>
          <el-select
            class="margin-right-twentyFour"
            v-model="status"
            placeholder="状态"
            style="width: 140px"
            clearable
            @clear="handlePageChange"
            @change="handlePageChange()"
          >
            <el-option label="未开始" :value="0"></el-option>
            <el-option label="进行中" :value="1"></el-option>
            <!-- 1 标签不能修改 -->
            <!-- 2 所有只能查看 -->
            <el-option label="结束" :value="2"></el-option>
          </el-select>
          <el-select
            v-model="platform"
            clearable
            filterable
            placeholder="平台"
            class="margin-right-ten"
            style="width: 120px"
          >
            <el-option
              v-for="item in messagePlatformListDetail"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>

          <tinyt-input width="200" v-model="remark" placeholder="备注">
          </tinyt-input>
          <el-select
            v-model="adminUserId"
            placeholder="请选择用户"
            filterable
            style="width: 120px; margin-left: 10px"
            clearable
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="item in userList"
              :key="item.id"
            ></el-option>
          </el-select>
          <el-button
            class="custom-button-margin-left"
            @click="handlePageChange(1)"
            type="success"
            >查询
          </el-button>

          <el-popover placement="bottom" width="700" trigger="hover">
            <el-table
              :row-style="{ cursor: 'pointer' }"
              max-height="500px"
              :data="uselessTempList"
              @row-click="handleUselessClick"
            >
              <el-table-column
                width="80px"
                property="id"
                label="文案id"
              ></el-table-column>
              <el-table-column
                width="80px"
                property="channelName"
                label="公众号"
              ></el-table-column>
              <el-table-column
                property="planName"
                label="文案名称"
              ></el-table-column>
              <el-table-column
                property="tagRemark"
                label="标签备注"
              ></el-table-column>
              <el-table-column
                property="templateName"
                label="模板名称"
              ></el-table-column>
            </el-table>
            <el-badge
              slot="reference"
              :value="uselessTempList.length"
              :max="99"
              class="item"
              type="warning"
            >
              <el-button class="custom-button-margin-left" type="warning"
                >失效模板</el-button
              >
            </el-badge>
          </el-popover>
        </div>
        <el-button type="primary" @click="handleAddPlan" icon="el-icon-plus"
          >方案</el-button
        >
      </div>
      <el-table
        class="table-service"
        v-loading="loading"
        :data="tableData"
        height="calc(100vh - 242px)"
        style="width: 100%"
      >
        <el-table-column prop="id" label="id" width="60"> </el-table-column>
        <el-table-column prop="planName" label="方案名称" width="140">
        </el-table-column>
        <el-table-column prop="channelName" label="公众号" width="100">
        </el-table-column>
        <el-table-column width="80" prop="status" label="状态">
          <template slot-scope="scoped">
            <el-tag type="info" v-if="scoped.row.status === 0">未开始</el-tag>
            <el-tag type="success" v-if="scoped.row.status === 1"
              >进行中</el-tag
            >
            <el-tag type="warning" v-if="scoped.row.status === 2">结束</el-tag>
            <template v-if="scoped.row.status === 3">
              <el-popover
                placement="right"
                width="300"
                trigger="hover"
                :content="scoped.row.failReason"
              >
                <el-tag slot="reference" type="warning">失败</el-tag>
              </el-popover>
            </template>
          </template>
        </el-table-column>
        <el-table-column prop="type" label="类型" width="80">
          <template slot-scope="{ row }">
            <span v-if="row.type == 1">循环发送</span>
            <span v-if="row.type == 2">一次发送</span>
            <span v-if="row.type == 3">关注发送</span>
          </template>
        </el-table-column>
        <el-table-column width="110" prop="tick" label="定时日期">
        </el-table-column>
        <el-table-column width="110" prop="tickTime" label="定时时间">
        </el-table-column>
        <el-table-column prop="intervalTime" label="间隔天数">
        </el-table-column>
        <el-table-column prop="sendCount" label="发送次数">
          <template slot="header" slot-scope="scope">
            <span
              ><span>发送次数</span>
              <el-tooltip
                style="margin-left: 4px"
                class="item"
                effect="dark"
                content="包含发送日期当天"
                placement="right"
              >
                <i class="el-icon-question"></i> </el-tooltip
            ></span>
          </template>
        </el-table-column>
        <el-table-column width="110" prop="nextStartDate" label="下次发送日期">
        </el-table-column>
        <el-table-column width="200" prop="createdTime" label="创建时间">
        </el-table-column>
        <el-table-column width="180" prop="remark" label="备注">
        </el-table-column>
        <el-table-column prop="action" label="操作" width="250">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="标签"
              placement="top-start"
              ><el-button
                type="warning"
                icon="el-icon-price-tag"
                circle
                @click="handleShowTag(scope.row)"
              >
              </el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="复制"
              placement="top-start"
            >
              <el-button
                type="info"
                icon="el-icon-document-copy"
                circle
                @click="handleShowCopy(scope.row)"
              >
              </el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="编辑"
              placement="top-start"
            >
              <el-button
                v-if="scope.row.status === 0 || scope.row.status === 2"
                type="success"
                icon="el-icon-edit"
                circle
                @click="handleEdit(scope.row)"
              >
              </el-button>
            </el-tooltip>
            <el-tooltip
              v-if="scope.row.status == 1"
              class="item"
              effect="dark"
              content="停止"
              placement="top-start"
            >
              <el-button
                type="warning"
                icon="el-icon-video-pause"
                circle
                @click="handlePause(scope.row)"
              >
              </el-button>
            </el-tooltip>
            <el-tooltip
              v-if="scope.row.type == 2 && scope.row.status == 2"
              class="item"
              effect="dark"
              content="启用"
              placement="top-start"
            >
              <el-button
                type="primary"
                icon="el-icon-caret-right"
                circle
                @click="handleStart(scope.row)"
              >
              </el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="删除"
              placement="top-start"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                circle
                @click="handleDelete(scope.row)"
              >
              </el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 10px; text-align: right"
        background
        @current-change="handlePageChange"
        :current-page="page"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
      <!-- 编辑新增 -->
      <editPlan
        :show="showAdd"
        :info="currentPlan"
        @close="showAdd = false"
        @refresh="handlePageChange(1)"
      />
      <!-- 排序弹框 -->
      <SortBox
        :show="showSort"
        labelProps="templateName"
        :sortList="tableData"
        @close="handleCancleSort"
        @sortChange="handleConfirmSort"
      />
      <!-- 详情弹框 -->
      <detail :show="showDetail" :info="current" @close="showDetail = false" />
      <!-- 复制弹窗 -->
      <copyPlan
        :show="showCopy"
        @confirm="handleCopyConfirm"
        :info="copyData"
        @close="showCopy = false"
      />
      <!-- 标签列表 -->
      <tagList
        v-if="showTag"
        :show="showTag"
        :account="channelList"
        :accountId="accountChannelId"
        :info="TagData"
        @close="showTag = false"
        @refresh="handlePageChange"
      />
    </div>
  </div>
</template>

<script>
import SortBox from "@/components/Sort";
import editPlan from "./editPlan";
import copyPlan from "./copyPlan";
import Detail from "./detail";
import tagList from "./tag/index";
import { mapGetters } from "vuex";
import {
  messagePlatformList,
  messagePlatformListDetail,
} from "@/assets/js/options";
import {
  getTemplatePlan,
  deleteTemplatePlan,
  stopTemplatePlan,
  getInvalidTemplatePlan,
} from "@/api/templateMessage";
import { sortTemplate } from "@/api/service";
export default {
  name: "tmplateService",
  components: {
    editPlan,
    SortBox,
    Detail,
    copyPlan,
    tagList,
  },
  data() {
    return {
      loading: false,
      status: null,
      page: 1,
      total: 0,
      pageSize: 15,
      tableData: [],
      current: null,
      CopyOfficial: [],
      currentPlatform: null,
      showSort: false,
      showDetail: false,
      copyLoading: false,
      accountChannelId: null,
      remark: null,
      platform: null,
      adminUserId: null,
      messagePlatformListDetail,
      uselessTempList: [],
      // 复制相关
      copyData: null,
      showCopy: false,
      copyForm: {},
      // 新增相关
      showAdd: false,
      isEdit: false,
      currentPlan: null,
      // 标签相关
      showTag: false,
      TagData: null,
    };
  },
  watch: {
    accountChannelId: {
      handler(newV) {
        let tmpplat = this.channelList.find((item) => {
          return item.id === this.accountChannelId;
        });
        if (tmpplat) {
          this.handlePageChange();
          this.currentPlatform = tmpplat.platform;
        }
      },
      immediate: true,
    },
  },
  computed: {
    channelList() {
      return this.officialList.filter(
        (item) =>
          messagePlatformList.includes(item.platform) && item.status === 1
      );
    },
    currentPlatChannelList() {
      return this.officialList.filter(
        (item) =>
          messagePlatformList.includes(item.platform) &&
          item.status === 1 &&
          item.platform === this.currentPlatform
      );
    },
    ...mapGetters(["officialList", "userList"]),
  },
  methods: {
    handleUselessClick(row) {
      this.TagData = row;
      this.showTag = true;
    },
    handleShowTag(row) {
      this.TagData = row;
      this.showTag = true;
    },
    handleAddPlan() {
      this.currentPlan = null;
      this.showAdd = true;
    },
    handleCopyConfirm() {
      this.showCopy = false;
      this.handlePageChange(1);
    },
    handleShowCopy(row) {
      this.copyForm = {};
      this.copyData = row;
      this.showCopy = true;
    },
    handleShowDetail(row) {
      this.current = row;
      this.showDetail = true;
    },
    handleCancleSort() {
      this.showSort = false;
    },
    handleConfirmSort(data) {
      sortTemplate({
        ids: data,
        accountChannelId: this.accountChannelId,
      }).then((res) => {
        this.$message.success("排序成功");
        this.handlePageChange();
      });
    },
    handleEdit(row) {
      this.currentPlan = row;
      this.showAdd = true;
    },
    handleStart(row) {
      this.$confirm("确定要启用此条数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        stopTemplatePlan(row.id)
          .then((res) => {
            this.$message.success("启用成功");
          })
          .finally(() => {
            this.handlePageChange();
          });
      });
    },
    handlePause(row) {
      this.$confirm("确定要停止此条数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        stopTemplatePlan(row.id)
          .then((res) => {
            this.$message.success("停止成功");
          })
          .finally(() => {
            this.handlePageChange();
          });
      });
    },
    handleDelete(row) {
      this.$confirm("确定要删除此条模板?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteTemplatePlan(row.id).then((res) => {
          this.$message.success("删除成功");
          this.handlePageChange();
        });
      });
    },
    handlePageChange(page) {
      typeof page === "number" ? (this.page = page) : "";
      this.loading = true;
      getTemplatePlan({
        page: this.page,
        pageSize: this.pageSize,
        accountChannelId: this.accountChannelId,
        status: this.status,
        remark: this.remark,
        platform: this.platform,
        adminUserId: this.adminUserId,
      })
        .then((res) => {
          this.tableData = res.list;
          this.total = res.total;
        })
        .finally(() => {
          this.loading = false;
        });
      // 获取无用模板
      getInvalidTemplatePlan().then((res) => {
        this.uselessTempList = res;
      });
    },
  },
  created() {
    this.handlePageChange();
    let tmpplat = this.channelList.find((item) => {
      return item.id === this.accountChannelId;
    });
    tmpplat ? (this.currentPlatform = tmpplat.platform) : "";
  },
};
</script>

<style lang="scss" scoped>
.operation {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // padding: 0 15px;
  // box-shadow: -2px -2px 10px rgba(180, 180, 180, 0.1);
}
.part {
  box-sizing: border-box;
  background: #fff;
  // box-shadow: -2px -2px 10px rgba(180, 180, 180, 0.1);
}
.copy-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}
/deep/ .el-badge__content.is-fixed {
  top: -4px !important;
}
</style>
