<template>
  <el-dialog
    title="模板消息详情"
    :visible.sync="show"
    center
    width="300px"
    top="20px"
    append-to-body
    :before-close="handleClose"
  >
    <div class="detail-box">
      <el-form label-width="0px" class="form-container">
        <el-form-item label="">
          <div class="review-box" v-if="wechartFormData.length">
            <div class="review">
              <div class="review-item">
                <p class="title">{{ form.templateName }}</p>
                <p class="desc" :style="'color:' + wechartFormData[0].color">
                  {{ wechartFormData[0].value }}
                </p>
                <template v-if="wechartFormData.length > 1">
                  <p
                    class="item"
                    v-for="(item, index) in wechartFormData.slice(
                      1,
                      wechartFormData.length
                    )"
                  >
                    <template v-if="item.title && item.key !== 'remark'">
                      <span class="name">{{ item.title }}：</span>
                      <span class="value">
                        <template v-if="item.value.includes('\n')">
                          <p
                            :style="'color:' + item.color"
                            v-for="item2 in item.value.split('\n')"
                          >
                            {{ item2 }}
                          </p>
                        </template>

                        <template v-else>
                          <p :style="'color:' + item.color">
                            {{ item.value }}
                          </p>
                        </template>
                      </span>
                    </template>
                    <!-- 备注 -->
                    <template v-else>
                      <span v-if="item.key === 'remark'" class="name"
                        >备注：</span
                      >
                      <span class="value">
                        <p :style="'color:' + item.color">
                          {{ item.value }}
                        </p>
                      </span>
                    </template>
                  </p>
                </template>
              </div>
              <div class="review-bottom">
                <p>
                  <span>查看详情</span>
                  <i class="el-icon-arrow-right"></i>
                </p>
              </div>
            </div>
            <p class="review-text">样式预览</p>
          </div>
        </el-form-item>
        <el-form-item label="链接：" label-width="100px">
          <p>{{ detail.urlName }}</p>
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
import { copyTemplatePlan } from "@/api/templateMessage";
export default {
  name: "detail",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    detail: Object,
  },
  data() {
    return {
      CopyOfficial: [],
      copyNum: null,
      form: {},
      wechartFormData: [],
    };
  },
  methods: {
    handleClose() {
      this.form = {};
      this.CopyOfficial = [];
      this.$emit("close");
    },
  },
  created() {
    this.wechartFormData = JSON.parse(this.detail.data);
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog__wrapper {
  overflow: hidden;
}
/deep/ .el-dialog__body {
  padding: 0 25px;
}

.service-detail {
  box-sizing: border-box;
  padding: 20px;
  height: 780px;
  overflow: auto;
}
.dialog-footer {
  display: inline-block;
  width: 100%;
  // margin-top:20px;
  text-align: center;
}
.form-container {
  max-height: 800px;
  // overflow: auto;
  padding-right: 10px;
}
.review-box {
  width: 240px;
  margin: 10px 4px;
  background: #f3f3f3;
  height: 100%;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  .review-text {
    margin: 20px 0;
    text-align: center;
    color: #bebebe;
    font-size: 16px;
  }
  .review {
    width: 220px;
    .review-item {
      background-color: #fff;
      padding: 6px;
      line-height: 16px !important;
      .title {
        font-size: 14px;
        margin-bottom: 10px;
      }
      .desc {
        font-size: 12px;
        margin-bottom: 10px;
        line-height: 14px;
      }
      .item {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 10px;
        .name {
          font-size: 14px;
          color: #b4b4b4;
          width: 90px;
        }
        .value {
          width: 120px;
          font-size: 12px;
          margin-left: 20px;
          line-height: 14px;
          p {
            line-height: 16px;
          }
        }
      }
    }
    .review-bottom {
      padding: 10px;
      background-color: #fff;
      border-top: 1px solid #eee;
      p {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}
.detail-box {
  padding-bottom: 20px;
}
</style>
