<template>
  <el-dialog
    :title="isEdit ? '修改方案' : '新增方案'"
    :visible.sync="show"
    center
    width="500px"
    top="100px"
    append-to-body
    :before-close="handleClose"
  >
    <div class="copy-box">
      <el-form label-width="100px">
        <el-form-item label="公众号：">
          <el-select
            :disabled="isEdit"
            v-model="accountChannelId"
            placeholder="请选择公众号"
            style="width: 280px"
            filterable
          >
            <el-option
              v-for="item in currentPlatChannelList"
              :key="item.id"
              :label="item.channelName"
              :value="item.id"
            >
              <span style="float: left">{{ item.channelName }}</span>
              <span style="float: right; margin-left: 15px; font-size: 13px"
                >{{ item.platformStr }} - {{ item.uname }}</span
              >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="方案名称：">
          <el-input
            style="width: 280px"
            v-model="form.planName"
            placeholder="请输入方案名称"
            size="normal"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="发送类型：">
          <el-select
            style="width: 280px"
            v-model="form.type"
            placeholder="选择发送类型"
          >
            <el-option label="循环发送" :value="1"> </el-option>
            <el-option label="一次发送" :value="2"> </el-option>
            <el-option label="关注发送" :value="3"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="定时时间：">
          <el-time-picker
            style="width: 280px"
            :picker-options="{
              selectableRange: '00:00:01 - 23:59:59',
            }"
            v-model="form.tickTime"
            value-format="HH:mm:ss"
            type="time"
            placeholder="选择时间"
          >
          </el-time-picker>
        </el-form-item>
        <el-form-item label="定时日期：">
          <el-date-picker
            style="width: 280px"
            v-model="form.tick"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="间隔天数：">
          <el-input-number
            style="width: 280px"
            v-model="form.intervalTime"
            placeholder="请输入间隔天数"
            size="normal"
            clearable
          ></el-input-number>
        </el-form-item>
        <el-form-item label="备注：">
          <el-input
            style="width: 280px"
            v-model="form.remark"
            placeholder="请输入备注"
            size="normal"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" :loading="subLoading" @click="handleConfirm"
        >确定</el-button
      >
      <el-button @click="handleClose">关 闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import {
  messagePlatformList,
  disableIncludeTodayOptions,
} from "@/assets/js/options";
import { addTemplatePlan, changeTemplatePlan } from "@/api/templateMessage";
export default {
  name: "edit",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    info: Object,
  },
  watch: {
    show: function (nv) {
      if (nv) {
        this.handleInit();
      }
    },
  },
  data() {
    return {
      isEdit: false,
      accountChannelId: null,
      copyNum: null,
      form: {},
      subLoading: false,
      pickerOptions: disableIncludeTodayOptions,
    };
  },
  computed: {
    ...mapGetters(["officialList"]),
    currentPlatChannelList() {
      return this.officialList.filter(
        (item) =>
          messagePlatformList.includes(item.platform) && item.status === 1
      );
    },
  },
  methods: {
    handleConfirm() {
      if (!this.accountChannelId && this.accountChannelId !== 0) {
        return this.$message.error("请选择公众号");
      }
      if (!this.form.planName) {
        return this.$message.error("请输入方案名");
      }
      if (!this.form.type) {
        return this.$message.error("请输入发送类型");
      }
      if (!this.form.tickTime) {
        return this.$message.error("请选择定时时间");
      }
      if (!this.form.tick) {
        return this.$message.error("请选择定时日期");
      }
      if (!this.form.intervalTime && this.form.intervalTime !== 0) {
        return this.$message.error("请输入间隔天数");
      }
      this.subLoading = true;
      if (!this.isEdit) {
        addTemplatePlan({
          accountChannelId: this.accountChannelId,
          ...this.form,
        })
          .then((res) => {
            this.$message.success("新增成功");
            this.$emit("refresh");
          })
          .finally(() => {
            this.handleClose();
            this.subLoading = false;
          });
      } else {
        changeTemplatePlan(this.info.id, {
          ...this.form,
        })
          .then((res) => {
            this.$message.success("修改成功");
          })
          .finally(() => {
            this.handleClose();
            this.subLoading = false;
            this.$emit("refresh");
          });
      }
    },
    handleClose() {
      this.form = {};
      this.accountChannelId = null;
      this.$emit("close");
    },
    handleInit() {
      this.isEdit = !!this.info;
      if (this.isEdit) {
        this.accountChannelId = this.info.accountChannelId;
        this.form = {
          planName: this.info.planName,
          tick: this.info.tick,
          tickTime: this.info.tickTime,
          intervalTime: this.info.intervalTime,
          remark: this.info.remark,
          type: this.info.type,
        };
      }
    },
  },
  created() {
    this.handleInit();
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog__wrapper {
  overflow: hidden;
}
/deep/ .el-dialog__body {
  padding-bottom: 0;
}

.service-detail {
  box-sizing: border-box;
  padding: 20px;
  height: 780px;
  overflow: auto;
}
.dialog-footer {
  display: inline-block;
  width: 100%;
  // margin-top:20px;
  text-align: center;
}
.form-container {
  max-height: 800px;
  // overflow: auto;
  padding-right: 10px;
}
</style>
