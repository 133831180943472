<template>
  <el-drawer
    :title="'标签列表：' + info.planName"
    :visible.sync="show"
    center
    size="90vw"
    append-to-body
    :before-close="handleClose"
  >
    <div class="tag-box">
      <dy-table
        :dataList="tableData"
        :rowList="rowList"
        :loading="loading"
        height="650px"
        :row-class-name="handleRowStyle"
        @pageChange="handlePageChange"
      >
        <template #filter>
          <div class="search-box">
            <el-button class="margin-left-ten" @click="handlePageChange"
              >刷新数据</el-button
            >
            <div class="search-right">
              <el-button
                v-if="info.status != 2"
                type="primary"
                @click="handleAddTag"
                icon="el-icon-plus"
                >标签</el-button
              >
            </div>
          </div>
        </template>
        <template #action="{ row }">
          <div>
            <el-tooltip
              class="item"
              effect="dark"
              content="模板"
              placement="top-start"
            >
              <el-button
                type="warning"
                icon="el-icon-s-order"
                circle
                @click="handleShowTemplate(row)"
              >
              </el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="详情"
              placement="top-start"
            >
              <el-button
                type="info"
                icon="el-icon-document"
                circle
                @click="handleDetail(row)"
              >
              </el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="复制"
              placement="top-start"
            >
              <el-button
                type="info"
                icon="el-icon-document-copy"
                circle
                @click="handleCopy(row)"
              >
              </el-button>
            </el-tooltip>
            <el-button
              type="success"
              icon="el-icon-edit"
              circle
              @click="handleEdit(row)"
            >
            </el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              @click="handleDelete(row)"
            >
            </el-button>
          </div>
        </template>
      </dy-table>
    </div>
    <!-- 新增编辑 -->
    <eidtTag
      :show="showAddTag"
      :account="account"
      :accountId="info.accountChannelId"
      :info="currentTag"
      :planId="info.id"
      @close="showAddTag = false"
      @refresh="handlePageChange"
    />
    <!-- 模板列表 -->
    <templateList
      :show="showTemplate"
      :info="currentTag"
      :account="account"
      :accountId="info.accountChannelId"
      @close="showTemplate = false"
    />
    <!-- 详情 -->
    <tagDetail
      :show="showDetail"
      :info="currentTag"
      :account="account"
      @close="showDetail = false"
    />
  </el-drawer>
</template>

<script>
import {
  getTemplateTag,
  deleteTemplateTag,
  copyTemplateTag,
} from "@/api/templateMessage";
import eidtTag from "./editTag.vue";
import tagDetail from "./tagDetail.vue";
import templateList from "./template/index.vue";
export default {
  name: "edit",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    info: Object,
    account: Array,
  },
  components: {
    eidtTag,
    templateList,
    tagDetail,
  },
  data() {
    return {
      CopyOfficial: [],
      copyNum: null,
      form: {},
      tableData: [],
      rowList: [
        {
          prop: "id",
          label: "id",
        },
        {
          prop: "remark",
          label: "备注",
          colWidth: "300",
          popover: true,
        },
        {
          prop: "records",
          label: "送达总人数",
        },
        {
          prop: "fixedNum",
          label: "送达人数限制",
        },
        {
          prop: "sendNum",
          label: "已发人数",
        },
        {
          prop: "createdTime",
          label: "创建时间",
        },
        {
          prop: "action",
          label: "操作",
          slot: true,
        },
      ],
      loading: false,
      showAddTag: false,
      currentTag: null,
      showTemplate: false,
      showDetail: false,
    };
  },
  watch: {
    show: function (nv) {
      if (nv) {
        this.handlePageChange();
      }
    },
  },
  computed: {},
  methods: {
    handleRowStyle(row, rowIndex) {
      if (this.info.tagId && row.row.id == this.info.tagId) {
        return "currentTag";
      }
    },
    handleCopy(row) {
      this.$confirm("确定要复制此条标签?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        copyTemplateTag(row.id).then((res) => {
          this.$message.success("复制成功");
          this.handlePageChange(1);
        });
      });
    },
    handleShowTemplate(row) {
      this.currentTag = row;
      this.showTemplate = true;
    },
    handleDelete(row) {
      this.$confirm("确定要删除此条标签?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteTemplateTag(row.id).then((res) => {
          this.$message.success("删除成功");
          this.handlePageChange();
        });
      });
    },
    handleDetail(row) {
      this.currentTag = row;
      this.showDetail = true;
    },
    handleEdit(row) {
      this.currentTag = row;
      this.showAddTag = true;
    },
    handleAddTag() {
      this.currentTag = null;
      this.showAddTag = true;
    },
    handlePageChange() {
      this.loading = true;
      getTemplateTag({
        planId: this.info.id,
      })
        .then((res) => {
          this.tableData = res;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleClose() {
      this.$emit("close");
    },
  },
  created() {
    this.handlePageChange();
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog__wrapper {
  overflow: hidden;
}
/deep/ .el-dialog__body {
  padding-bottom: 0;
}

.service-detail {
  box-sizing: border-box;
  padding: 20px;
  height: 780px;
  overflow: auto;
}
.dialog-footer {
  display: inline-block;
  width: 100%;
  // margin-top:20px;
  text-align: center;
}
.form-container {
  max-height: 800px;
  // overflow: auto;
  padding-right: 10px;
}
.search-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .search-right {
    margin-right: 10px;
  }
}
/deep/ .currentTag {
  background-color: #81ffe5;
}
</style>
