<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="isEdit ? '修改模板' : '新增模板'"
    :visible.sync="show"
    center
    width="800px"
    top="100px"
    append-to-body
    :before-close="handleClose"
  >
    <div class="copy-box">
      <el-form label-width="100px">
        <div class="message">
          <el-input
            class=""
            v-model="form.urlName"
            disabled
            placeholder="请插入跳转链接"
          ></el-input>
          <template>
            <div style="margin: 0 10px; height: 32px; line-height: 32px">
              <insert-book
                :channel="selectChannel"
                @success="handleSelectSuccess"
              />
              <insert-page
                :channel="selectChannel"
                @success="handleSelectSuccess"
              />
              <insert-activity
                :channel="selectChannel"
                :type="'news'"
                @success="handleSelectSuccess"
              />
            </div>
          </template>
          <div class="select-box">
            <el-select
              style="width: 50%"
              v-model="form.templateId"
              placeholder="选择模板"
              @change="handleChangeWechatTemplate"
            >
              <el-option
                v-for="(item, index) in wechatTemplate"
                :key="item.template_id"
                :label="item.title"
                :value="item.template_id"
              ></el-option>
            </el-select>
            <span style="margin-left: 4px"
              >用户昵称：<span style="color: #ff4949">@{$nickname}</span></span
            >
            <!-- <input type="text" id="copyInput" width="0" /> -->
          </div>
          <div class="message-container">
            <div class="message-right">
              <div class="review-box" v-if="wechartFormData.length">
                <div class="review">
                  <div class="review-item">
                    <p class="title">{{ selectedWechatTmp.title }}</p>
                    <p
                      class="desc"
                      :style="'color:' + wechartFormData[0].color"
                    >
                      {{ wechartFormData[0].value }}
                    </p>
                    <template v-if="wechartFormData.length > 1">
                      <p
                        class="item"
                        v-for="(item, index) in wechartFormData.slice(
                          1,
                          wechartFormData.length
                        )"
                      >
                        <template v-if="item.title && item.key !== 'remark'">
                          <span class="name">{{ item.title }}：</span>
                          <span class="value">
                            <template v-if="item.value.includes('\n')">
                              <p
                                :style="'color:' + item.color"
                                v-for="item2 in item.value.split('\n')"
                              >
                                {{ item2 }}
                              </p>
                            </template>

                            <template v-else>
                              <p :style="'color:' + item.color">
                                {{ item.value }}
                              </p>
                            </template>
                          </span>
                        </template>
                        <!-- 备注 -->
                        <template v-else>
                          <span v-if="item.key === 'remark'" class="name"
                            >备注：</span
                          >
                          <span class="value">
                            <p :style="'color:' + item.color">
                              {{ item.value }}
                            </p>
                          </span>
                        </template>
                      </p>
                    </template>
                  </div>
                  <div class="review-bottom">
                    <p>
                      <span>查看详情</span>
                      <i class="el-icon-arrow-right"></i>
                    </p>
                  </div>
                </div>
                <p class="review-text">样式预览</p>
              </div>
            </div>
            <div class="message-left">
              <template v-if="wechartFormData.length">
                <div
                  class="message-area"
                  v-for="(item, index) in wechartFormData"
                >
                  <el-input
                    @blur="handleBlur($event, index)"
                    v-model="wechartFormData[index].value"
                    style="width: 350px"
                    :id="'tmpInput' + index"
                    type="textarea"
                    :placeholder="getPlaceholder(selectedWechatTmp.data[index])"
                  ></el-input>
                  <el-color-picker
                    size="mini"
                    v-model="wechartFormData[index].color"
                  ></el-color-picker>
                </div>
              </template>
            </div>
          </div>
        </div>
        <el-form-item
          v-if="wechartFormData.length"
          label="测试人"
          style="margin-bottom: 14px"
        >
          <el-select
            v-model="testId"
            filterable
            remote
            reserve-keyword
            placeholder="请输入用户名"
            :remote-method="userQuery"
            :loading="remoteLoading"
            @change="handleTestChange"
          >
            <el-option
              v-for="(item, index) in testUser"
              :key="index"
              :label="item.nickname"
              :value="item.openId"
            >
              <div class="row">
                <user-avatar :info="item" name-prop="nickname" :size="25" />
                <span style="margin-left: 10px">{{ item.nickname }}</span>
              </div>
            </el-option>
          </el-select>
          <el-button
            style="margin-left: 10px"
            type="primary"
            :loading="loadingButton"
            @click="handleTest"
            >发送</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" :loading="subLoading" @click="handleConfirm"
        >确定</el-button
      >
      <el-button @click="handleClose">关 闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
import InsertBook from "@/views/service/insertBook/index";
import InsertPage from "@/views/service/insertPage/index";
import InsertActivity from "@/views/service/insertActivity/index";
import UserAvatar from "@/components/UserAvatar";
import { mapGetters } from "vuex";
import {
  getWechatTemplate,
  addTemplateContent,
  changeTemplateContent,
  sendTemplateContentTest,
} from "@/api/templateMessage";
import { getAccountUser } from "@/api/service";
export default {
  name: "edit",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    info: Object,
    accountId: Number,
    account: Array,
    detail: Object,
  },
  components: {
    InsertBook,
    InsertPage,
    InsertActivity,
    UserAvatar,
  },
  watch: {
    show: function (nv) {
      if (nv) {
        this.handleInit();
      }
    },
  },
  data() {
    return {
      isEdit: false,
      accountChannelId: null,
      copyNum: null,
      form: {},
      subLoading: false,
      wechatTemplate: [],
      selectedWechatTmp: {
        data: [],
        id: null,
      },
      wechartFormData: {},
      currenWechat: {},
      currentWechatTemplate: {},
      selectChannel: {},
      remoteLoading: false,
      testUser: [],
      loadingButton: false,
      testId: null,
    };
  },
  computed: {
    ...mapGetters(["officialList"]),
    getPlaceholder() {
      return function (data) {
        if (data.title) {
          return data.title + "：" + data.value;
        } else {
          return data.value;
        }
      };
    },
  },
  methods: {
    // 发送测试
    handleTest() {
      if (!this.form.url) {
        this.$message.error("请输入跳转链接");
        return false;
      }
      if (!this.selectedWechatTmp.id) {
        return this.$message.error("请选择模板");
      }
      let values = this.wechartFormData.map((item) => {
        return item.value;
      });
      if (values.includes("")) {
        return this.$message.error("请完善模板信息");
      }
      if (!this.testId) {
        this.$message.error("请输入测试openid");
        return false;
      }
      const params = {
        openId: this.testId,
        templateId: this.selectedWechatTmp.id,
        data: JSON.stringify(this.wechartFormData),
        accountChannelId: this.selectChannel.id,
        url: this.form.url,
      };
      this.loadingButton = true;
      sendTemplateContentTest(params)
        .then(() => {
          this.$message.success("发送成功");
        })
        .finally(() => {
          this.loadingButton = false;
        });
    },
    // 缓存发送人
    handleTestChange(item) {
      let nickName = null;
      this.testUser.forEach((element) => {
        if (element.openId === item) {
          nickName = element.nickname;
        }
      });
      sessionStorage.setItem("testNickName", nickName);
      sessionStorage.setItem("testId", item);
    },
    handleSelectSuccess({ link, name, url, bookTitle }) {
      this.form.url = url;
      this.form.urlName = name;
      this.$forceUpdate();
    },
    handleBlur(event, index) {
      this.textIndex = index;
      this.textPosition = event.srcElement.selectionStart;
    },
    handleChangeWechatTemplate(id) {
      let index = this.wechatTemplate.findIndex((item) => {
        return item.template_id === id;
      });
      this.selectedWechatTmp.data = [];
      this.wechartFormData = [];
      this.currentWechatTemplate = this.wechatTemplate[index];
      this.wechartFormData = this.wechatTemplate[index].data.map((item) => {
        return {
          title: item.title,
          key: item.key,
          value: "",
          color: item.color,
        };
      });
      this.selectedWechatTmp.data = this.wechatTemplate[index].data;
      this.selectedWechatTmp.id = this.wechatTemplate[index].template_id;
      this.selectedWechatTmp.title = this.wechatTemplate[index].title;
    },
    handleConfirm() {
      if (!this.form.url) {
        this.$message.error("请输入跳转链接");
        return false;
      }
      if (!this.selectedWechatTmp.id) {
        return this.$message.error("请选择模板");
      }
      let values = this.wechartFormData.map((item) => {
        return item.value;
      });
      if (values.includes("")) {
        return this.$message.error("请完善模板信息");
      }
      this.subLoading = true;
      if (!this.isEdit) {
        addTemplateContent({
          templateId: this.selectedWechatTmp.id,
          templateName: this.selectedWechatTmp.title,
          data: JSON.stringify(this.wechartFormData),
          tagId: this.info.id,
          url: this.form.url,
          urlName: this.form.urlName,
        })
          .then((res) => {
            this.$message.success("新增成功");
          })
          .finally(() => {
            this.handleClose();
            this.subLoading = false;
            this.$emit("refresh");
          });
      } else {
        changeTemplateContent(this.detail.id, {
          templateId: this.selectedWechatTmp.id,
          templateName: this.selectedWechatTmp.title,
          data: JSON.stringify(this.wechartFormData),
          url: this.form.url,
          urlName: this.form.urlName,
        })
          .then((res) => {
            this.$message.success("修改成功");
          })
          .finally(() => {
            this.handleClose();
            this.subLoading = false;
            this.$emit("refresh");
          });
      }
    },
    handleClose() {
      this.form = {};
      this.selectedWechatTmp = {
        data: [],
        id: null,
      };
      this.wechartFormData = {};
      this.accountChannelId = null;
      this.$emit("close");
    },
    userQuery(name) {
      this.remoteLoading = true;
      getAccountUser(this.accountId, name)
        .then((res) => {
          this.testUser = res;
        })
        .finally(() => (this.remoteLoading = false));
    },
    handleInit() {
      const defaultAccount = this.account.find(
        (item) => item.id === this.accountId
      );
      this.selectChannel = {
        id: this.accountId,
        channelName: defaultAccount ? defaultAccount.channelName : "",
        platform: defaultAccount ? defaultAccount.platform : null,
      };
      // 获取微信模板
      getWechatTemplate(this.accountId).then((res) => {
        this.wechatTemplate = res;
      });
      // 获取测试人员
      if (sessionStorage.getItem("testNickName")) {
        this.userQuery();
        this.testId = sessionStorage.getItem("testId");
      } else {
        this.testId = null;
      }
      this.estimateNum = null;
      this.userQuery();
      this.isEdit = !!this.detail;
      if (this.isEdit) {
        const {
          content,
          accountChannelId,
          templateId,
          templateName,
          channelName,
          data,
          ...other
        } = this.detail;
        this.selectedWechatTmp = {
          data: JSON.parse(data),
          id: templateId,
          title: templateName,
        };
        this.form = other;
        this.wechartFormData = JSON.parse(data);
        this.form.templateId = templateId;
        this.form.urlName = other.urlName;
      }
    },
  },
  created() {
    this.handleInit();
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog__wrapper {
  overflow: hidden;
}
/deep/ .el-dialog__body {
  padding-bottom: 0;
}

.service-detail {
  box-sizing: border-box;
  padding: 20px;
  height: 780px;
  overflow: auto;
}
.dialog-footer {
  display: inline-block;
  width: 100%;
  // margin-top:20px;
  text-align: center;
}
.form-container {
  max-height: 800px;
  // overflow: auto;
  padding-right: 10px;
}

.message-container {
  margin: 10px 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  .message-left {
    width: 370px;
    .message-area {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    > div {
      margin-bottom: 10px;
    }
  }
  .message-right {
    width: 240px;
    .review-box {
      margin: 0 4px;
      background: #f3f3f3;
      height: 100%;
      padding-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      .review-text {
        margin: 20px 0;
        text-align: center;
        color: #bebebe;
        font-size: 16px;
      }
      .review {
        width: 220px;
        .review-item {
          background-color: #fff;
          padding: 6px;
          .title {
            font-size: 14px;
            margin-bottom: 10px;
          }
          .desc {
            font-size: 12px;
            margin-bottom: 10px;
            line-height: 14px;
          }
          .item {
            width: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            margin-bottom: 10px;
            .name {
              font-size: 14px;
              color: #b4b4b4;
              width: 90px;
            }
            .value {
              width: 120px;
              font-size: 12px;
              margin-left: 20px;
              line-height: 14px;
              p {
                line-height: 16px;
              }
            }
          }
        }
        .review-bottom {
          padding: 10px;
          background-color: #fff;
          border-top: 1px solid #eee;
          p {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }
      }
    }
  }
  /deep/ .el-color-picker__trigger {
    width: 20px !important;
  }
}
.fixed-number-tips {
  color: #e6bb99;
}
.select-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
</style>
